import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Title } from '@angular/platform-browser';
import {HelperService} from "../shared/services/helper.service";

declare var require: any;
const qs = require('qs');

@Component({
  selector: 'app-search-details',
  templateUrl: './search-details.component.html',
  styleUrls: ['./search-details.component.css']
})
export class SearchDetailsComponent implements OnInit {

  query: string
  searchRecords: any = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private title: Title,
    private helperService: HelperService
    ) {
      this.title.setTitle(this.route.snapshot.data['title'])
    this.helperService.showFooter.next(false)
    this.route.queryParamMap.subscribe(params => {
         let param = params['params']
         this.query = param.query
         this.apiService.search(this.query).subscribe(
           res => {
            if(res.suggests.hasOwnProperty('_')) {
              this.searchRecords = res.suggests._
            } else {
              this.searchRecords = []
            }

             this.helperService.showFooter.next(true)
           }
         )
      })
     }

  ngOnInit() {
  }

  sort(ev) {
    if(ev.target.value == 'low_to_high') {
      this.searchRecords.sort((a, b) => (a.actual_price < b.actual_price) ? -1 : 1)
    } else {
      this.searchRecords.sort((a, b) => (a.actual_price > b.actual_price) ? 1 : -1)
    }
  }

}
