import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  @ViewChild('modal', {static:false }) public modal: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService, private helperService: HelperService) {
    this.createForm()
   }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

 
  onSubmit(form) {
    if(this.form.valid) {
     this.apiService.forgot(form).subscribe(
       res => {
         this.helperService.successAlert(res.message, 'Success')
         this.modal.nativeElement.click()
         this.form.reset()
       },
       err => {
         this.helperService.errorAlert(err.error.errors[0], 'Error')
       }
     )
   } else {
     this.apiService.touchAllFields(this.form)
   }
  }
}
