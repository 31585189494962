import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  storagePath: string
  transform(value: any, baseUrl: boolean = false): any {
    this.storagePath = environment.storageUrl
    if(value != null && !baseUrl) {
      return this.storagePath + value;
    } else {
      return environment.base_url + value;
    }
  }

}
