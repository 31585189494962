import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '../../libs/localstorage';
import { Title } from '@angular/platform-browser';
import { AccountService } from 'src/app/my-account/account.service';
import { HelperService } from '../../services/helper.service';
import {CartService} from "../../services/cart.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  form: FormGroup;
  redirectPath: string;
  redirectPathSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorage,
    private accountService: AccountService,
    private cartService: CartService,
    private title: Title
  ) {
      this.createForm()
      this.title.setTitle(this.route.snapshot.data['title'])
      this.redirectPathSubscription = this.helperService.redirectpath.asObservable().subscribe(path => this.redirectPath = path)
  }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    }, { updateOn: 'submit'})
  }

  onSubmit(form) {
    if(this.form.valid) {
     this.apiService.login(form).subscribe(
       res => {
        this.accountService.setLoggedInStatus(true)
         this.localStorage.setObject('user', res.result)
         this.localStorage.set('token', res.result.access_token)
         this.apiService.syncCart().subscribe(res => console.log());
         this.cartService.getCart();
         this.cartService.getCartAgain.next(true)
         this.router.navigate([this.redirectPath])
         this.form.reset()
       },
       err => {
        if(err.error.error == "Unauthorized") {
          this.helperService.errorAlert('Invalid email or password', 'Error')
        } else if(err.error.message = "Validation Errors") {
          this.helperService.errorAlert(err.error.errors[0], 'Error')
        }
       }
     )
   } else {
     this.apiService.touchAllFields(this.form)
   }
  }

  ngOnDestroy() {
    this.redirectPathSubscription.unsubscribe();
  }
}
