import { Directive, ElementRef, Renderer, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appExpandLists]'
})
export class ExpandListsDirective {

  @Input() values: any
  @Input() icon: string

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }


  ngAfterContentInit() {
    if(this.values) {
      let checkItem = this.values.find(x => x.checked == true)
      if(checkItem) {
        this.renderer.setAttribute(this.element.nativeElement, 'style', 'display:block')
        if(this.icon)
          this.renderer.addClass(this.element.nativeElement, this.icon)
      }
    }
  }

}
