import { Component, OnInit, Input } from '@angular/core';
import { Perks } from './Perks';

@Component({
  selector: 'app-perk',
  templateUrl: './perk.component.html',
  styleUrls: ['./perk.component.css']
})
export class PerkComponent implements OnInit {

  @Input() perks = [Perks]

  constructor() { }

  ngOnInit() {
  }

}
