import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { LocalStorage } from '../libs/localstorage';
import { AuthService } from '../auth/auth.service ';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { CartService } from '../services/cart.service';
import { isPlatformBrowser } from '@angular/common';
import { AccountService } from 'src/app/my-account/account.service';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logo: string = ''
  token: string;
  isLoggedIn: boolean = false;
  cartCount: number
  showSearch: boolean = false
  searchRecords: any
  searchQuery: string
  suggestProducts: any[] = [];

  constructor(private localStorage: LocalStorage, public auth: AuthService, public apiServices: ApiService, private router: Router,
    private cartService: CartService, @Inject(PLATFORM_ID) private platformId, private accountService: AccountService,
              private helperService: HelperService) {

      // if(isPlatformBrowser(this.platformId)) {
      //   if (this.auth.isAuthenticated()) {
      //     this.isLoggedIn = !this.isLoggedIn
      //   }
      // }

    this.accountService.currentLoggedInStatus.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn)
    this.cartService.currentCartCount.subscribe(count => this.cartCount = count)

   }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.getContactDetails()
    }
  }

  search(ev) {
    let value = ev.target.value
    if(!value) {
      this.searchRecords = null
    } else {
      this.apiServices.search(value, 20).subscribe(
        res => {
          this.searchRecords = []
          if(res.result.products.length > 0) {
            this.searchRecords = res.result.products
          }
          // else if(res.suggests.hasOwnProperty('_')) {
          //   this.searchRecords = res.suggests._
          // } 
          else {
            this.searchRecords = []
            this.apiServices.productSuggestions().subscribe(response => {
              this.suggestProducts = response.result
            })
          }
        }
      )

    }
  }

  getContactDetails() {
    const settings = this.localStorage.getObject('site_settings')
    if(settings != undefined) {
      this.logo = settings.find(x => x.key == 'site.logo').value
    }
  }

  logout() {
    this.apiServices.logout().subscribe(res => {
      this.accountService.setLoggedInStatus(false)
      this.localStorage.remove('token')
      this.localStorage.remove('user')
      this.localStorage.remove('cart')
      this.cartService.setCartCount(0)
      this.router.navigate(['/']);
    })
  }

  goToCart() {
    if(this.cartCount) {
      this.router.navigate(['/cart']);
    } else {
      const msg = `<h5>Your cart is empty</h5> <p>Looks like you haven’t added any item to you cart yet.</p>`
      this.helperService.successAlert(msg, '', '/assets/images/empty-cart.png', '')
    }
  }

  goToProfile() {
    window.location.href= '/account'
    // this.router.navigateByUrl('/account')
  }

  gotoLink(link) {
    this.router.navigateByUrl(link)
  }

  searchDetailPage() {
    if(this.searchQuery != '') {
      this.router.navigate(['/search-details'], {queryParams: {query: this.searchQuery}})
    }
  }
}
