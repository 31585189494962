export class Categories {
    id: number
    name: string
    slug: string
    parent_id: number
    image_url: string 
    created_at: string 
    updated_at: string 
    status: boolean
}
