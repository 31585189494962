
//  const BASE_URL = 'kadmin.dotlogicstest.com';
 // const BASE_URL = 'http://localhost:8000/';
const BASE_URL = 'https://kadmin.dotlogicstest.com/';

export const environment = {
  production: false,
  site360_siteId: 'ka.dotlogicstest.com',
  base_url: BASE_URL,
  apiUrl: BASE_URL + 'api/',
  storageUrl: BASE_URL + 'storage/'
};
