import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var require: any;

const qs = require('qs');

@Component({
  selector: 'app-product-filters',
  templateUrl: './product-filters.component.html',
  styleUrls: ['./product-filters.component.css']
})
export class ProductFiltersComponent implements OnInit {

  @Input() filters: any = []
  @Input() categoryPriceFilters: any = []
  @Input() priceRanges: any = []
  @Input() occasions: any = []
  @Input() productsLength;
  @Output() filterProducts = new EventEmitter

  filterOption: any = {}
  options: any = {};
  queryParams: any = {}
  ocassionSelected: any = []
  selectedPrice: any;
  selectedInsta: boolean = false

  constructor(private router: Router, private route: ActivatedRoute, private elementRef: ElementRef) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.filters && changes.filters.currentValue.length > 0 || changes.priceRanges) {
      this.route.queryParamMap.subscribe(params => {
        this.filterOption = params['params']
        let opt = this.filterOption

        Object.keys(opt).forEach(option => {
          this.options[option] = opt[option].split(',')
        });

        Object.keys(this.filterOption).forEach(opt => {
          if(opt == 'occasions') {
              let occasions = this.filterOption[opt].split(',')
              let occasionIndex = this.filters.findIndex(x => x.product_option == 'By Occasion')
              this.filters[occasionIndex].option_values.forEach(value => {
                   if(occasions.find(x => x == value.id)) {
                      value.checked = true
                    }
              })
          } else if(opt == 'price') {
              let prices = this.filterOption[opt].split(',')
             // this.selectedPrice = prices[1]
            let index = this.filters.findIndex(x => x.product_option == 'Prices')

            this.filters[index].option_values.forEach(item => {
              let price = item.price.toString()
               if(prices.includes(price)) {
                 item.checked = true
               }
             })
            } else if(opt == 'price_above') {
              let index = this.filters.findIndex(x => x.product_option == 'Prices')
              this.filters[index].option_values[this.filters[index].option_values.length - 1].checked = true
            } else if( opt == 'uploadedToInsta') {
              this.selectedInsta = true;
            } else if(this.filters.length > 0 && opt != 'page' && opt != 'orderBy' && opt != 'sort') {
              let index = this.filters.findIndex(x => x.product_option == opt)
              this.filters[index].option_values.forEach(value => {
                if(this.options[opt].find(x => x == value.id)) {
                  value.checked = true
                }
              });
          }
        })
      });
    }
  }

  optionChecked(event) {
    let value = event.target.value.split('|')
    if(event.target.checked) {
      if(this.options.hasOwnProperty(value[0]) && this.options[value[0]].length != 0) {
        this.options[value[0]].push(value[1])
      } else {
        this.options[value[0]]  = [value[1]]
      }
    } else {
      let index = this.options[value[0]].findIndex(x => x == value[1])
      this.options[value[0]].splice(index, 1)
    }

    this.pushPath()

  }


  pushPath() {
    let opt = {}
    Object.keys(this.options).forEach(option => {
        if(this.options[option].length > 0)
          opt[option] = this.options[option].join(',')
        else if(this.options[option].length != 0)
          opt[option] = this.options[option]
    });

    this.filterOption = opt
    this.filterOption.page = 1
    this.filterProducts.emit(this.filterOption)
    this.router.navigate([], {queryParams: this.filterOption})
  }

  priceSelected(event, item) {

    if(event.target.checked) {
      item.checked = true
    } else {
      item.checked = false
    }

    let prices = []
    let priceFilters = this.filters.find(x => x.product_option == 'Prices')
    priceFilters.option_values.forEach( async (x, i) => {
      if(x.checked && i != priceFilters.option_values.length - 1) {
        prices.push(x.price)
      }
    })
    this.options.price = prices
    // console.log('options', this.options.price)
   this.pushPath()
  }


  maxPriceSelected = (event, filter, maxPrice) => {
    debugger
    if(event.target.checked) {
      filter.checked = true
      this.options.price_above = maxPrice
    } else {
      filter.checked = false
      delete this.options.price_above
    }

    this.pushPath()
  }

  byInsta() {
    let opt = {}
    Object.keys(this.options).forEach(option => {
        if(this.options[option].length > 0)
          opt[option] = this.options[option].join(',')
    });

    this.selectedInsta = true;
    this.filterOption = opt
    this.filterOption.page = 1
    this.filterOption['uploadedToInsta'] = 1
    this.filterProducts.emit(this.filterOption)
    this.router.navigate([], {queryParams: this.filterOption})
  }

  clearPrice() {
    let opt = {}
    Object.keys(this.filterOption).forEach(option => {
      if(option != 'price')
          opt[option] = this.filterOption[option]
    })
    this.selectedInsta = false
    this.filterProducts.emit(opt)
    this.router.navigate([], {queryParams: opt})

  }

  resetAll() {
    this.options = []
    this.filterProducts.emit({})
    this.router.navigate([])
  }

  isEmpty(object) {
    return Object.keys(object).length === 0
  }




}
