import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-recent-blogs',
  templateUrl: './recent-blogs.component.html',
  styleUrls: ['./recent-blogs.component.css']
})
export class RecentBlogsComponent implements OnInit {

  recentPost:any;
  @Input() currentBlog: any 
  
  constructor(private apiService: ApiService, private helperService: HelperService) { }

  ngOnInit() {
    this.getRecentPosts();
  }

  getRecentPosts() {
    this.apiService.recentPosts().subscribe(
      res => {
        this.recentPost = res.result
      }, 
      err => {
        this.helperService.errorAlert('Something went wrong', 'Error')
      }
    )
  }

}
