import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.css']
})
export class RelatedProductsComponent implements OnInit {

  @Input() products = []
  @Input() category: string 
  @Output() getProduct = new EventEmitter

  constructor() { }

  ngOnInit() {
  }


  onClickProduct(product) {
    this.getProduct.emit(product.slug)  
  }
}
