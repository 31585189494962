import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

  @Input() text: string = 'No Product Found'
  constructor() { }

  ngOnInit() {
  }

}
