import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  categories: any = []
  pagesMenu: any 

  constructor(private apiService: ApiService, private helperService: HelperService) { }

  ngOnInit() {
    this.getPagesMenu()
    this.getCategories();
  }

  getCategories() {
    this.apiService.productCategories().subscribe(
      res => {
        this.categories = res.result
      },
      err => {
        console.log('err', err)
      }
    )
  }


  getPagesMenu() {
    this.apiService.getPagesMenu().subscribe(res => {
      this.pagesMenu = res.result;
    })
  }

}
