import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  showFooter = new BehaviorSubject<boolean>(false)
  footerVisibility = new BehaviorSubject<boolean>(false)

  redirectpath = new BehaviorSubject<string>('/account/profile');

  constructor() { }

  successAlert(message: string = '', title = '', imageUrl: string = '', icon: any = 'success') {
    Swal.fire({
      title: title,
      html: message,
      imageUrl: imageUrl,
      icon: icon,
      confirmButtonText: 'OK',
      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'icon-class',
        image: 'image-class',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class',
        footer: 'footer-class'
      }
    })
  }

  errorAlert(message: string, title = 'Error') {
    Swal.fire({
      title: title,
      html: message,
      icon: 'error',
      confirmButtonText: 'Close',
      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'icon-class',
        image: 'image-class',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class',
        footer: 'footer-class'
      }
    })
  }

  setFooterVisibility(status: boolean) {
    this.showFooter.next(status)
  }

  getFooterVisibility(): Observable<boolean> {
    return this.showFooter.asObservable();
  }
}
