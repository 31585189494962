import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { ApiService } from '../api.service';
import { LocalStorage } from '../libs/localstorage';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartCount =  new BehaviorSubject<any>(0);
  currentCartCount = this.cartCount.asObservable();

  private cart = new BehaviorSubject<any>({});
  currentCart = this.cart.asObservable();

  private checkoutData = new BehaviorSubject<any>({});
  currentCheckoutData = this.checkoutData.asObservable();

  private order = new BehaviorSubject<any>({});
  currentOrder = this.order.asObservable();

  getCartAgain = new BehaviorSubject<boolean>(false);
  isCartFetchAgain = this.getCartAgain.asObservable();

  orderMessage = new BehaviorSubject<any>('');

  constructor(private apiService: ApiService, private localStorage: LocalStorage) { }

  setCartCount(count) {
    this.cartCount.next(count)
  }

  setCart(data) {
    this.cart.next(data)
  }

  getCartCount() {
    return this.cartCount
  }

  setOrder(order) {
    this.order.next(order)
  }

  setCheckoutData(data) {
    this.checkoutData.next(data)
  }

  getCart() {
    this.apiService.mycart().subscribe(res => {
      // this.cart = res.result
      this.localStorage.setObject('cart', res.result)
      let cartCount = 0;
      res.result.products.forEach(product => {
        cartCount = Number(cartCount) + Number(product.quantity)
      })
      this.cart.next(res.result)
      this.setCartCount(cartCount)
      //console.clear();
    }, err => {
      this.localStorage.remove('cart')
      this.cart = null
      this.setCartCount(0)
      //this.router.navigate(['/'])
     // console.clear();
    })
  }

  getOrderMessage() : Observable<any> {
    return this.orderMessage.asObservable();
  }

}
