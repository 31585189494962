import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-review-address',
  templateUrl: './review-address.component.html',
  styleUrls: ['./review-address.component.css']
})
export class ReviewAddressComponent implements OnInit {

  @Input() data: any 
  @Input() title: string 
  
  constructor() { }

  ngOnInit() {
  }

}
