import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import { isPlatformBrowser} from '@angular/common';

const KEY_PREFIX = "ka";
// declare var localStorage: any
@Injectable()
export class LocalStorage {
    public localStorage:any;

    constructor(@Inject(PLATFORM_ID) private platformId) {
        if(isPlatformBrowser(this.platformId)) {
            if (!localStorage) {
                throw new Error('Current browser does not support Local Storage');
            }
            this.localStorage = localStorage;
        }
       
           
    }

    private generateStorageKey(key: string): string {
        return `${KEY_PREFIX}_${key}`;
    }

    public set(key:string, value:string):void {
        let storageKey = this.generateStorageKey(key);
        this.localStorage[storageKey] = value;
    }

    public get(key:string):string {
        let storageKey = this.generateStorageKey(key);
        return this.localStorage[storageKey] || false;
    }

    public setObject(key:string, value:any):void {
        let storageKey = this.generateStorageKey(key);
        this.localStorage[storageKey] = JSON.stringify(value);
    }

    public getObject(key:string):any {
        let storageKey = this.generateStorageKey(key);
        return JSON.parse(this.localStorage[storageKey] || '{}');
    }

    public remove(key:string):any {
        let storageKey = this.generateStorageKey(key);
        this.localStorage.removeItem(storageKey);
    }
}
