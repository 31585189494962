import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SideNavComponent} from './side-nav/side-nav.component';
import {RouterModule} from '@angular/router';
import {CategoryComponent} from './components/category/category.component';
import {ImageUrlPipe} from './pipes/image-url.pipe';
import {PerkComponent} from './components/perk/perk.component';
import {TeamComponent} from './components/team/team.component';
import {LocalStorage} from './libs/localstorage';
import {ContactFormComponent} from './components/contact-form/contact-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaqComponent} from './components/faq/faq.component';
import {SidebarMenuComponent} from './components/sidebar-menu/sidebar-menu.component';
import {RecentBlogsComponent} from './components/recent-blogs/recent-blogs.component';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {LoginPopupComponent} from './components/login-popup/login-popup.component';
import {AuthService} from './auth/auth.service ';
import {AuthGuardService} from './auth/auth-guard.service';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ExcerptPipe} from './pipes/excerpt.pipe';
import {ProductFiltersComponent} from './components/product-filters/product-filters.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {RelatedProductsComponent} from './components/related-products/related-products.component';
import {ReviewsComponent} from './components/reviews/reviews.component';
import {StarRatingModule} from 'angular-star-rating';
import {ProductPriceComponent} from './components/product-price/product-price.component';
import { ProductOptionsComponent } from './components/product-options/product-options.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { ReviewAddressComponent } from './components/review-address/review-address.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { SanitizeHTMLPipe } from './pipes/sanitize-html.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ExpandListsDirective } from './directives/expand-lists.directive';
import { HelperService } from './services/helper.service';
import {AuthInterceptorService} from "./services/auth-interceptor.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    CategoryComponent,
    ImageUrlPipe,
    PerkComponent,
    TeamComponent,
    ContactFormComponent, FaqComponent, SidebarMenuComponent,
    RecentBlogsComponent, SubscribeComponent, LoginPopupComponent, ForgotPasswordComponent,
    ExcerptPipe, ProductFiltersComponent, SafeHtmlPipe, LoginFormComponent, RelatedProductsComponent,
    ReviewsComponent, ProductPriceComponent, ProductOptionsComponent, OrderSummaryComponent, ReviewAddressComponent,
    FormatDatePipe,
    SanitizeHTMLPipe, NotFoundComponent, ExpandListsDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    StarRatingModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    CategoryComponent,
    ImageUrlPipe,
    PerkComponent,
    TeamComponent,
    ContactFormComponent,
    FaqComponent,
    SidebarMenuComponent,
    RecentBlogsComponent,
    SubscribeComponent,
    LoginPopupComponent,
    ForgotPasswordComponent,
    ExcerptPipe,
    ProductFiltersComponent,
    SafeHtmlPipe,
    LoginFormComponent,
    RelatedProductsComponent,
    ReviewsComponent,
    ProductPriceComponent,
    ProductOptionsComponent,
    OrderSummaryComponent,
    ReviewAddressComponent,
    FormatDatePipe,
    SanitizeHTMLPipe,
    NotFoundComponent,
    ExpandListsDirective
  ],
  providers: [
    ImageUrlPipe,
    LocalStorage,
    AuthService,
    AuthGuardService,
    ExcerptPipe,
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ]
})
export class SharedModule {
}
