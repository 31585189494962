import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';
import {Constants} from "../../constants/Constants";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  form: FormGroup
  disableSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private apiServices: ApiService,
    private toastr: ToastrService,
    private helperService: HelperService
  ) {
    this.createForm()
  }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required, Validators.maxLength(150)]],
      message: ['', Validators.required]
    })
  }

  submit(form) {
    if(this.form.valid) {
      this.disableSubmit = true
      this.apiServices.addContactQuery(form).subscribe(
        res => {
          this.disableSubmit = false
          this.helperService.successAlert('Thank you for contacting us, we\'ll be in touch.', '', Constants.logo, '')
          this.form.reset();
        },
        err => {
          this.disableSubmit = false
          this.helperService.errorAlert(err.error.message)
        }
      )
    } else  {
      this.apiServices.touchAllFields(this.form)
    }
  }

}
