import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css']
})
export class SidebarMenuComponent implements OnInit {

  @Input() menus: any = []
  @Input() slug: string;
  @Output() routePage = new EventEmitter()

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  linkClick(link) {
    this.slug = link
    //this.router.navigate(['/post/' + this.slug]) 
    this.routePage.emit(link)    
  }

}
