import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '../../libs/localstorage';
import { AccountService } from 'src/app/my-account/account.service';
import { HelperService } from '../../services/helper.service';
import {CartService} from "../../services/cart.service";

declare var $: any

@Component({
  selector: 'app-login-form',
  templateUrl: './login-popup.component.html',
})
export class LoginPopupComponent implements OnInit {

  form: FormGroup
  hideForm: boolean = true

  constructor(private fb: FormBuilder, private apiService: ApiService, private helperService: HelperService, private route: ActivatedRoute,
    private router: Router, private localStorage: LocalStorage, private accountService: AccountService,
              private cartService: CartService) {
      this.createForm()
     }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    }, { updateOn: 'submit'})
  }

  onSubmit(form) {

    let location = window.location.pathname

    if(this.form.valid) {

     this.apiService.login(form).subscribe(
       res => {
         $('.login-form').slideToggle()
         this.localStorage.set('token', res.result.access_token)
         this.accountService.setLoggedInStatus(true)
         this.localStorage.setObject('user', res.result)
         this.apiService.syncCart().subscribe(res => console.log());
         this.cartService.getCart();
         this.cartService.getCartAgain.next(true)
         this.router.navigateByUrl(location)
         this.form.reset()
       },
       err => {
         if(err.error.hasOwnProperty('error') && err.error.error == "Unauthorized") {
           this.helperService.errorAlert('Invalid username / password', 'Error')
         } else {
           this.helperService.errorAlert(err.error.errors[0], 'Error')
         }
       }
     )
   } else {
     this.apiService.touchAllFields(this.form)
   }
  }
}
