import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LayoutComponent } from './layout/layout.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ApiService } from './shared/api.service';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './shared/auth/auth.service ';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { JwtModule,JwtHelperService,JwtModuleOptions } from '@auth0/angular-jwt';
import { SearchDetailsComponent } from './search-details/search-details.component';
import { MetaModule } from '@ngx-meta/core';
import { DisqusModule } from 'ngx-disqus';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

export function gettoken (){
  return localStorage.getItem('access_token');
};

const jwtConf: JwtModuleOptions = {
  config: {
      tokenGetter: gettoken,
    // whitelistedDomains: ['localhost:4200']
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SearchDetailsComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpClientModule,
    NgProgressModule.withConfig({
      spinner: false
    }),
    NgProgressHttpModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }), // ToastrModule added,
    BrowserAnimationsModule,
    JwtModule.forRoot(jwtConf),
    MetaModule.forRoot(),
    DisqusModule.forRoot('https-ka-dotlogicstest-com'),
  ],
  providers: [ApiService, AuthService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
