import { Routes, RouterModule } from '@angular/router';
import { SearchDetailsComponent } from '../search-details/search-details.component';
import { AuthGuardService } from '../shared/auth/auth-guard.service';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';

export const Full_ROUTES: Routes = [
  {
    path: '',
    loadChildren: './pages/pages.module#PagesModule',
  }, 
  {
    path: 'post',
    loadChildren: './posts/posts.module#PostsModule'
  }, 
  {
    path: 'blogs',
    loadChildren: './blogs/blogs.module#BlogsModule'
  }, 
  {
    path: 'products',
    loadChildren: './products/products.module#ProductsModule',
  },
  {
    path: 'account',
    loadChildren: './my-account/my-account.module#MyAccountModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'cart',
    loadChildren: './cart/cart.module#CartModule'
  },
  {
    path: 'search-details',
    component: SearchDetailsComponent,
    data: {
      title: 'Search Details'
    }
  },
  
];