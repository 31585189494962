import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excerpt'
})
export class ExcerptPipe implements PipeTransform {

  transform(value: any, length = 40): any {
    
    if(value) {
      value = value.substring(0, length)
    }

    return value
    
  }

}
