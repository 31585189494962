import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Categories } from './Categories';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  @Input() categories = [Categories]
  @Input() isOccassions = false 
  @Input() viewMoreLink = '/categories'
  link: string = '/products/categories'

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
   
    if(this.isOccassions) {
      this.link = '/products/occasions'
    } 
  }

  productListing(category) {
    if(!this.isOccassions) {
      this.router.navigate(['/products/categories', category.slug])
    } 
  }
}
