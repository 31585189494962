import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {

  form: FormGroup
  constructor(private fb: FormBuilder, private apiService: ApiService, private helperService: HelperService) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ''
    })
   }

  ngOnInit() {
  }

  onSubmit(form) {
    if(this.form.valid) {
      this.apiService.subscribe(form).subscribe(
        res => {
          this.form.reset();
          this.helperService.successAlert(res.message, 'Success')
        },
        err => {
          this.helperService.errorAlert(err.error.message, 'Error')
        }
      )
    } else {
      this.apiService.touchAllFields(this.form)
    }
  }

}
