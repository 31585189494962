import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../shared/auth/auth.service ';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private title: string;
  subTitleBreadCrumb: any

  private subTitle =  new BehaviorSubject<any>('');
  subTitleBreadCrum = this.subTitle.asObservable();

  private titlePath =  new BehaviorSubject<any>('');
  currentTitlePath = this.titlePath.asObservable();

  private isLoggedIn =  new BehaviorSubject<boolean>(false);
  currentLoggedInStatus = this.isLoggedIn.asObservable();

  private breadCrumTitle =  new BehaviorSubject<any>('');
  breadCrumPageTitle = this.breadCrumTitle.asObservable();

  constructor( @Inject(PLATFORM_ID) private platformId, private auth: AuthService) {
    if(isPlatformBrowser(this.platformId)) {
      if (this.auth.isAuthenticated()) {
        this.isLoggedIn.next(true)
      }
    }
  }


  setLoggedInStatus(value: boolean) {
    this.isLoggedIn.next(value)
  }

  setTitle(title: string) {
    this.title = title
  }

  setTitlePath(path: string) {
    this.titlePath.next(path)
  }

  getTitle() {
    return this.title
  }

  setBreadCrumSubtitle(title) {
    this.subTitle.next(title)
  }

  setBreadCrumPageTitle(path: string) {
    this.breadCrumTitle.next(path)
  }
}
