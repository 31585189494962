import {Component, Input, NgModule, OnInit, SimpleChanges} from '@angular/core';
import {Reviews} from '../../../products/Products';


@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})

export class ReviewsComponent implements OnInit {


  @Input() reviews: [Reviews];
  limit: number = 3

  constructor() {
  }

  ngOnInit() {
  }

  viewMore() {
    this.limit += 3;
    console.log('limit', this.limit)
    console.log('reviews length', this.reviews.length)
  }

}
