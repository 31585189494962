import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  private metaTags =  new BehaviorSubject<any>({});
  currentMeta = this.metaTags.asObservable();
  
  constructor() { }

  isEmpty(object) {
    return Object.keys(object).length === 0
  }

  setMetaTags(data: any) {
    this.metaTags.next(data)
  }
}
