import {ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {LocalStorage} from '../libs/localstorage';
import {isPlatformBrowser} from '@angular/common';
import {HelperService} from '../services/helper.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  showFooter: boolean = false;
  subscription: Subscription;
  newsLetterForm: FormGroup
  footer_menu_1: any = [];
  footer_menu_2: any = [];
  email: any = 'support@dcjewelery.com';
  phone: any = '743-5678-559';
  logo: string = ''

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private helperService: HelperService,
    private router: Router,
    private localStorage: LocalStorage,
    @Inject(PLATFORM_ID) private platformId,
    private changeDetectorRef: ChangeDetectorRef
    ) {
    this.newsletterFormElements();

   }


  ngOnInit() {
    this.getMenu('footer_menu_1')
    this.getMenu('footer_menu_2')
    this.initFooterSettings()

    this.subscription = this.helperService.getFooterVisibility().subscribe(status => {
      this.showFooter = status
      this.changeDetectorRef.detectChanges();

    })
  }

  initFooterSettings() {
    if(isPlatformBrowser(this.platformId)) {
      const setings = this.localStorage.getObject('site_settings')
      if (setings.length) {
        this.email = setings.find(x => x.key == 'site.email').value
        this.phone = setings.find(x => x.key == 'site.phone').value
        this.logo = setings.find(x => x.key == 'site.logo').value
      }


    }

  }

  newsletterFormElements() {
    this.newsLetterForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      agree: ['']
    }, { updateOn: 'submit'})
  }


  onSubmitNewsletter(form) {
    if(this.newsLetterForm.valid) {
      this.apiService.subscribe(form).subscribe(
        res => {
          this.newsLetterForm.reset();
          this.helperService.successAlert(res.message, 'Success')
        },
        err => {
          this.helperService.errorAlert(err.error.message, 'Error')
        }
      )
    } else {
      this.apiService.touchAllFields(this.newsLetterForm)
    }
  }

  getMenu(menu) {
    this.apiService.getMenu(menu).subscribe(
      res => {
        this[menu] = res.result.items
      }
    )
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }


}
