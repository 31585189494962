import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { LocalStorage } from '../shared/libs/localstorage';
import { CartService } from '../shared/services/cart.service';
import { MetaTagsService } from '../shared/services/meta-tags.service';
import { MetaService } from '@ngx-meta/core';
import { isPlatformBrowser } from '@angular/common';
import {HelperService} from "../shared/services/helper.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private localStorage: LocalStorage,
    private cartService: CartService,
    private meta: MetaService,
    private metaTagsService: MetaTagsService,
    @Inject(PLATFORM_ID) private platformId,
    private helperService: HelperService
    ) {

  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.siteSettings()

      let sessionId = this.localStorage.get('sessionId')
      if(!sessionId) {
        sessionId = Math.random().toString(36).substr(2, 25);
        this.localStorage.set('sessionId', sessionId)
      }
    }

    this.metaTagsService.currentMeta.subscribe(
      tags => {
        if(!this.metaTagsService.isEmpty(tags)) {
          this.meta.setTitle(tags.title)
          this.meta.setTag('keywords', tags.meta_keywords)
          this.meta.setTag('description', tags.meta_description)

          if(tags.og) {
            this.meta.setTag('og:title', tags.og.title);
            this.meta.setTag('og:keywords', tags.og.meta_keywords);
            this.meta.setTag('og:description', tags.og.meta_description);
            this.meta.setTag('og:image', tags.og.image);
            this.meta.setTag('og:url', tags.og.url);
          }
        }
      }
    )

  }

  onActivate(event) {
    if(isPlatformBrowser(this.platformId)) {
      window.scroll(0,0);
    }
  }

  siteSettings() {
    this.apiService.siteSettings().subscribe(
      res => {
        this.localStorage.setObject('site_settings', res.result)
      }
    )


    let cart = this.localStorage.getObject('cart')
    if(this.isEmpty(cart) || cart == undefined) {
      this.apiService.mycart().subscribe(res => {
        if(res.status) {
          this.localStorage.setObject('cart', res.result)
          let cartCount = 0;
          res.result.products.forEach(product => {
            cartCount = Number(cartCount) + Number(product.quantity)
          })
          this.cartService.setCartCount(cartCount)
        }
      }, err => {
       // console.clear();
      })
    } else {
      let cartCount = 0;
        cart.products.forEach(product => {
          cartCount = Number(cartCount) + Number(product.quantity)
        })
      this.cartService.setCartCount(cartCount)
    }

    //console.clear();
  }

  isEmpty(object) {
    return Object.keys(object).length === 0
  }


  ngAfterViewInit() {
  }
}


