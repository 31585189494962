import { Component, HostListener } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { MetaTagsService } from './shared/services/meta-tags.service';
import {HelperService} from "./shared/services/helper.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'DC';

  constructor() {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {

    let headerElement = document.getElementById('sticky-header');
    let headerCover = document.getElementById('headerCover');

    var mainBody = document.body;

    if(headerElement) {
      if(!headerElement.classList.contains("sticky-header")){
        headerCover.style.height = headerElement.offsetHeight + 'px';
      }
    }

    if (document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50) {
        document.getElementById('sticky-header').classList.add('sticky-header');
        headerCover.style.display = 'block';
        mainBody.classList.add("removeBstyle");
    } else {
      headerCover.style.display = 'none';
      document.getElementById('sticky-header').classList.remove('sticky-header');
      mainBody.classList.remove("removeBstyle");
    }
  }



}


