import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse , HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Homepage } from '../pages/home-page/Homepage';
import { About } from '../pages/about-us/About';
import { Page } from '../pages/Page';
import { Post } from '../posts/Post';
import { Blogs } from '../blogs/Blogs';
import { User, UserData } from './components/login-popup/User';
import { Products, Category, ProductCategories, Product, SingleProduct, ProductVariant } from '../products/Products';
import { ActivatedRoute } from '@angular/router';
import { Filters } from './components/product-filters/Filters';
import { LocalStorage } from './libs/localstorage';
import { AddressBooks, AddressBook } from '../my-account/address-book/add-address/AddressBook';
import { Orders, Order, SingleOrder } from '../my-account/orders/Order';
import { MetaService } from '@ngx-meta/core';

declare var require: any;

const qs = require('qs');

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private token;
  private url;

  constructor(private http: HttpClient, private route: ActivatedRoute, private localStorage: LocalStorage, private meta: MetaService) {
    this.url = environment.apiUrl
   }

   isEmpty(object) {
    return Object.keys(object).length === 0
   }

   resetLink(token: string) : Observable<any> {
    const url = `${this.url}validateResetLink/${token}`
    return this.http.get(url);
  }

  public loadScript(url: string, type: string = 'text/javascript') {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.type = type
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

    touchAllFields(form) {
    //touch all fields to show the error
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

   homepage() : Observable<Homepage> {
      const url = this.url + 'page/homepage'
      return this.http.get<Homepage>(url);
   }

   about(slug: string): Observable<About> {
    const url = this.url + 'page/' + slug
    return this.http.get<About>(url);
   }

   page(slug: string) : Observable<Page> {
    const url = this.url + 'page/' + slug
    return this.http.get<Page>(url);
   }

   addContactQuery(formData: Object) : Observable<any> {
     const url = this.url + 'contact-queries';
     return this.http.post(url, formData);
   }

  submitReview(formData: Object) : Observable<any> {
    const url = this.url + 'product-reviews?token='+this.localStorage.get('token');
    return this.http.post(url, formData);
  }

   getPost(slug: string) :Observable<Post> {
    const url = this.url + 'post/' + slug
    return this.http.get<Post>(url);
   }

   getMenu(name?: string) : Observable<any> {
    const url = this.url + 'menus/' + name
    return this.http.get(url);
   }


   getPagesMenu() : Observable<any> {
    const url = this.url + 'pages-menu'
    return this.http.get(url);
   }
   //postMenu/sidebar-posts
   getSidebarMenu(slug?: string) : Observable<any>  {
    const url = this.url + 'postMenu/' + slug
    return this.http.get(url);
   }

   getBlogs(category?: string) : Observable<Blogs> {
    let url = this.url + 'posts'

    if(category) {
      url = url + '?category=' + category
    }

    return this.http.get<Blogs>(url);
   }

   singlePost(id: number) : Observable<Post> {
    const url = this.url + 'posts/' + id
    return this.http.get<Post>(url);
   }

   recentPosts() : Observable<Post> {
    const url = this.url + 'posts/recent'
    return this.http.get<Post>(url);
   }

   subscribe(formData): Observable<any> {
     const url = this.url + 'subscribe'
     return this.http.post(url, formData)
   }

   siteSettings() : Observable<any> {
    const url = this.url + 'settings'
    return this.http.get(url)
   }

    /* ------------------------------------------------ */
   /*         USER ACCOUNT APIs
   /* ------------------------------------------------ */

   register(formData): Observable<any> {
     const url = this.url + 'users'
     return this.http.post(url, formData)
   }

   updateOrder(formData: object) : Observable<any> {
    const url = this.url + 'orders/update'
    return this.http.post(url, formData)
   }

   login(formData) : Observable<User> {
    const url = this.url + 'auth/login'
    return this.http.post<User>(url, formData)
   }

   //logout
   logout() : Observable<any> {
    const url = this.url + 'logout?token=' + this.localStorage.get('token')
    return this.http.get(url)
   }

   //forgot
   forgot(formData) : Observable<any> {
    const url = this.url + 'forgot_password'
    return this.http.post<any>(url, formData)
   }

   //reset Password
   reset(formData):  Observable<any> {
    const url = this.url + 'password/reset'
    return this.http.post<any>(url, formData)
   }

   user() : Observable<UserData> {
      let token = this.localStorage.get('token')
      const url = `${this.url}user?token=${token}`
      return this.http.get<UserData>(url);
   }

   updateUser(formData: Object, id) : Observable<any> {
    let token = this.localStorage.get('token')
    const url = `${this.url}users/${id}?token=${token}`
    return this.http.put<any>(url, formData);
   }


   updatePassword(formData: Object) : Observable<any> {
    let token = this.localStorage.get('token')
    const url = `${this.url}user/change_password?token=${token}`
    return this.http.post<any>(url, formData);
   }



   /* ------------------------------------------------ */
   /*               PRODUCTS
   /* ------------------------------------------------ */

  products(query: Object) : Observable<Products> {
    let url = this.url + 'products'
    query = qs.stringify(query)
    if(query) url = url + '?' + query
    return this.http.get<Products>(url)
  }

  productsByCategory(slug: string, query: Object) : Observable<Products> {
    let url = this.url + 'products/getByCategory/' + slug
    query = qs.stringify(query)
    if(query) url = url + '?' + query

    return this.http.get<Products>(url)
  }

  getProduct(category: string, slug: string) : Observable<SingleProduct> {
    let url = this.url + 'product/' + category + '/' + slug
    return this.http.get<SingleProduct>(url)
  }

  productCategories() : Observable<ProductCategories> {
    const url = this.url + 'categories?isPagination=0&active=1'
    return this.http.get<ProductCategories>(url)
  }

  productOptions(category: string) : Observable<Filters> {
    const url = `${this.url}products/getOptions/${category}`
    return this.http.get<Filters>(url);
  }

  filterProducts(formData: object) : Observable<Products> {
    let url = this.url + 'products/filter'
    return this.http.post<Products>(url, formData)
  }

  occasions() : Observable<any> {
    const url = `${this.url}ocassions?isPagination=0&active=1`
    return this.http.get(url);
  }

  productVariant(slug: string) : Observable<ProductVariant> {
    const url = `${this.url}product/variant/${slug}`
    return this.http.get<ProductVariant>(url);
  }

  productSuggestions() : Observable<any> {
    const url = `${this.url}products/suggestions`
    return this.http.get<any>(url);
  }

  /* ------------------------------------------------ */
   /*         CART API
   /* ------------------------------------------------ */


  addToCart(formData: object) : Observable<any> {
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + this.localStorage.get('token')});
    const url = `${this.url}cart/add`
    return this.http.post(url, formData, { headers: headers});
  }

  mycart(): Observable<any> {
    let sessionId = this.localStorage.get('sessionId')
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + this.localStorage.get('token')});
    const url = `${this.url}cart/mycart?session_id=${sessionId}`
    return this.http.get(url, {headers:headers});
  }

  updateCart(formData: any, cartID: number): Observable<any> {
    const url = `${this.url}cart/update/${cartID}`
    return this.http.post(url, formData, {headers: this.getAuthorizationHeader()});
  }

  getAuthorizationHeader(): HttpHeaders {
    return new HttpHeaders({'Authorization': 'Bearer ' + this.localStorage.get('token')});
  }

  deleteCartItem(id: number) : Observable<any> {
    const url = `${this.url}cart/delete/${id}`
    return this.http.delete(url);
  }

  changeQuantity(formData: object) : Observable<any> {
    const url = `${this.url}cart/changeQuantity`
    return this.http.post(url, formData);
  }

  checkout(formData: Object) : Observable<any> {
    //payment/pay
    const url = `${this.url}payment/checkout`
    return this.http.post(url, formData, {headers: this.getAuthorizationHeader()});
  }


  myCards(): Observable<any> {
    const url = `${this.url}payment/my-saved-cards?token=${this.localStorage.get('token')}`
    return this.http.get(url);
  }



  syncCart() : Observable<any> {
    const header = new HttpHeaders({'Authorization': 'Bearer ' + this.localStorage.get('token')})
    const url = `${this.url}cart/syncCart/${this.localStorage.get('sessionId')}`;
    return this.http.get(url, {headers: header});
  }

   /* ------------------------------------------------ */
   /*         IMAGE CUSTOMIZATOIN API
   /* ------------------------------------------------ */

   engraving(formData: any) :  Observable<any> {
   //  const query = qs.stringify(formData)
     const url = `${this.url}image/engraving`
     return this.http.post(url, formData );

   }


    /* ------------------------------------------------ */
   /*         MISCELLENIOUS
   /* ------------------------------------------------ */

  countries(): Observable<any> {
    const url = `${this.url}countries`
    return this.http.get(url);
  }

  states(countryId): Observable<any> {
    const url = `${this.url}states/${countryId}`
    return this.http.get(url);
  }

  mywishlist() : Observable<any> {
    const url = `${this.url}wishlist?token=${this.localStorage.get('token')}`
    return this.http.get(url);
  }

  productWishList(product_id: number) : Observable<any> {
    const url = `${this.url}wishlist/${product_id}?token=${this.localStorage.get('token')}`
    return this.http.get(url);
  }

  wishlist(formData: Object) : Observable<any> {
    //wishlist/addOrRemove
    const url = `${this.url}wishlist/addOrRemove?token=${this.localStorage.get('token')}`
    return this.http.post(url, formData);
  }

   /* ------------------------------------------------ */
   /*              ADDRESS BOOK APIs
   /* ------------------------------------------------ */

   defaultAddresses() : Observable<any> {
    let url = `${this.url}defaultAddresses?token=` + this.localStorage.get('token')
    return this.http.get<any>(url);
   }

   addressBooks() : Observable<any> {
    let url = `${this.url}addressBooks?token=` + this.localStorage.get('token')
    return this.http.get<any>(url);
   }

   applyCoupon(formData) : Observable<any> {
     //discount/calculate
     const headers = new HttpHeaders({'Authorization': 'Bearer ' + this.localStorage.get('token')});
     let url = `${this.url}discount/calculate`
     return this.http.post<any>(url, formData, {headers});
   }

   removeCoupon(formData) : Observable<any> {
    //discount/remove
    let url = `${this.url}discount/remove`
    return this.http.post<any>(url, formData);
  }

   /* ------------------------------------------------ */
   /*               BASIC CRUD
   /* ------------------------------------------------ */

   save(model:string, formData: object, token?: string) : Observable<any> {
    let url = `${this.url}${model}`
    if(token) {
      url += '?token=' + token
    }
    return this.http.post(url, formData);
   }

   all(model:string, isPagination: number = 1, token?: string) : Observable<any> {
    let url = `${this.url}${model}?isPagination=${isPagination}`
    if(token) {
      url += '&token=' + token
    }
    return this.http.get(url);
   }


   single(model:string, id: number, token?: string) : Observable<any> {
    let url = `${this.url}${model}/${id}`
    if(token) {
      url += '?token=' + token
    }
    return this.http.get(url);
   }


   update(model:string, formData: object, id: number, token?: string) : Observable<any> {
    let url = `${this.url}${model}/${id}`
    if(token) {
      url += '?token=' + token
    }
    return this.http.put(url, formData);
   }


   delete(model:string, id: number, token?: string) : Observable<any> {
    let url = `${this.url}${model}/${id}`
    if(token) {
      url += '?token=' + token
    }
    return this.http.delete(url);
   }

   /* ------------------------------------------------ */
   /*               ORDERS
   /* ------------------------------------------------ */

   myOrders(query: Object) : Observable<Orders> {
    query = qs.stringify(query)
    let url = `${this.url}orders/myOrders?${query}&token=` + this.localStorage.get('token')
    return this.http.get<Orders>(url);
   }

   orderById(order_id: number) : Observable<SingleOrder> {
    let url = `${this.url}orders/getById/${order_id}?token=${this.localStorage.get('token')}`
    return this.http.get<SingleOrder>(url);
   }

   getMyOrderNumbers() : Observable<any> {
    let url = `${this.url}orders/getMyOrderNumbers?token=${this.localStorage.get('token')}`
    return this.http.get(url);
   }

    /* ------------------------------------------------ */
   /*               SITE360 SEARCH
   /* ------------------------------------------------ */

  //  search(query: string, limit = null) : Observable<any> {
  //     const headers = new HttpHeaders({'ignoreProgressBar': ''});
  //     let url = `https://api.sitesearch360.com/sites?query=${query}&site=${environment.site360_siteId}&highlightQueryTerms=true&filterOptions=true&includeContent=true`

  //     if(limit) {
  //       url += '&limit=' + limit
  //     }
  //     return this.http.get(url, {headers: headers})
  //  }

   search(query: string, limit = null) : Observable<any> {
    let url = this.url + 'products/searchProducts/' + query
    const headers = new HttpHeaders({'ignoreProgressBar': ''});
    return this.http.get(url)
  }

   validateUserEmail(email: string) {
    const headers = new HttpHeaders({'ignoreProgressBar': ''});
    let url = `${this.url}user/checkEmail/${email}`
    return this.http.get(url, {headers: headers})
   }

   exists(data): boolean {
    return data !== undefined && data !== null && data !== '';
   }

   addMetaTags(data: any) {
     this.meta.setTag('twitter:card', 'summary');
    if(this.exists(data.meta_title)) {
      this.meta.setTitle(data.meta_title);
      this.meta.setTag('og:title', data.meta_title);
      this.meta.setTag('twitter:title', data.meta_title);
    } else if(this.exists(data.name)){
      this.meta.setTitle(data.name);
      this.meta.setTag('og:title', data.name);
      this.meta.setTag('twitter:title', data.name);
    }

    if(this.exists(data.meta_keywords)) {
      this.meta.setTag('keywords', data.meta_keywords)
      this.meta.setTag('og:keywords', data.meta_keywords);
    }

    if(this.exists(data.meta_description)) {
      this.meta.setTag('description', data.meta_description)
      this.meta.setTag('og:description', data.meta_description);
      this.meta.setTag('twitter:description', data.meta_description);
    }

    // if(this.exists(data.meta_description)) {
    //   this.meta.setTag('description', data.meta_description)
    // }


    if(this.exists(data.image_full_url)) {
      this.meta.setTag('og:image', environment.storageUrl + data.image_full_url);
      this.meta.setTag('twitter:image', environment.storageUrl + data.image_full_url);
    }

    this.meta.setTag('og:url', window.location.href);
  }



  /* ------------------------------------------------ */
  /*              MY ACCOUNT
  /* ------------------------------------------------ */

  myPaymentCards() : Observable<any> {
    let url = `${this.url}payment/my-saved-cards?token=${this.localStorage.get('token')}`
    return this.http.get(url);
  }

  deletePaymentCard(formData: any) : Observable<any> {
    let url = `${this.url}payment/delete-card?token=${this.localStorage.get('token')}`
    return this.http.post(url, formData);
  }

  addPaymentCard(formData: any) : Observable<any> {
    let url = `${this.url}payment/save_card?token=${this.localStorage.get('token')}`
    return this.http.post(url, formData);
  }


  removeMetaTags() {
    this.meta.removeTag('name=title');
    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:keywords"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:url"');
  }

}
