import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorage } from '../libs/localstorage';

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService, private localStorage: LocalStorage) {}
  // ...
  public isAuthenticated(): boolean {
    const token = this.localStorage.get('token')
    // Check whether the token is expired and return
    // true or false
    if(token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }
}